import styles from './Header.module.scss'
import { useEffect } from 'react';
import { Link } from "react-router-dom";


function Header() {

  
  
  useEffect(() => {
    const detailsElements = document.querySelectorAll('details');

    const handleToggle = (event) => {
      const detail = event.target;
      const content = detail.querySelector('p');


      if (detail.open) {
        const contentHeight = content.scrollHeight;
        detail.style.height = `${contentHeight + 40}px`;
      } else {
        detail.style.height = '60px';
      }
    };

    detailsElements.forEach((detail) => {
      detail.addEventListener('toggle', handleToggle);
    });


    return () => {
      detailsElements.forEach((detail) => {
        detail.removeEventListener('toggle', handleToggle);
      });
    };
  }, [])
  return (
    <div className={styles.home}>
      <div className={`header ${styles.header}`} id='header'>
        <div className={`header1 ${styles.header1}`} id={styles.headerVideo}>
          <div className={styles.videoContainer}>
            <video
              className={`img1 ${styles.img1}`}
              src="home.mp4"
              alt="vid1"
              controls
              allow="autoplay; fullscreen"
              allowFullScreen
            ></video>
          </div>
          <h1 className={`h1 ${styles.h1}`}>Développe ton <br />JIU JITSU <br />avec <span className={`drill ${styles.drill}`}>DRILL IT</span></h1>
        </div>

        <div className={`header1 ${styles.header1}`} id='header1'>
          <div className={`infodrill ${styles.infodrill}`}>
            <p className={`pinfodrill ${styles.pinfodrill}`}>Tous niveaux</p>
            <h2 className={`h2 ${styles.h2}`}>Entraînements conçu <br /> pour tous</h2>
            <p className={`p2 ${styles.p2}`}>Que vous soyez débutant ou avancé, nos cours de Jiu-Jitsu
              Brésilien et de Judo sont faits pour vous. Nos professeurs adaptent les techniques à votre
              niveau pour vous faire progresser. Rejoignez-nous pour développer vos compétences et atteindre
              vos objectifs.</p>
          </div>
          <img className={`img2 ${styles.img2}`} src="niveau.jpg" alt="img1" />
        </div>

        <div className={`header1 ${styles.header1}`} id='header1'>
          <img className={`img2 ${styles.img2}`} src="outdoor.jpg" alt="img2" />
          <div className={`infodrill ${styles.infodrill}`}>
            <p className={`pinfodrill ${styles.pinfodrill}`}>N'IMPORTE OÙ, N'IMPORTE QUAND</p>
            <h2 className={`h2 ${styles.h2}`}>Accédez à tout moment <br /> à vos cours</h2>
            <p className={`p2 ${styles.p2}`}>Accédez à tout moment à vos cours de Jiu-Jitsu Brésilien en ligne.
              Suivez les leçons à votre rythme, où que vous soyez, avec des vidéos et ressources disponibles 24/7.
              Apprenez et progressez sans contrainte de temps ou de lieu.
            </p>
          </div>
        </div>

        <div className={`header2 ${styles.header2}`} id='header1'>
          <div className={`infodrill ${styles.infodrill}`}>
            <p className={`pinfodrill ${styles.pinfodrill}`}>Expertise de Renommée Mondiale</p>
            <h2 className={`h2 ${styles.h2}`}>Apprenez des Meilleurs</h2>
            <p className={`p2 ${styles.p2}`}>Profitez de l'expertise de nos professeurs de renommée mondiale sur Drill-it.
              Chaque cours est conçu pour vous offrir des techniques éprouvées et selon votre niveau. Apprenez des meilleurs,
              suivez des entraînements de qualité et améliorez vos compétences avec des démonstrations détaillées.
            </p>
          </div>
          <img className={`img2 ${styles.img2}`} src="meilleur.jpg" alt="img1" />
        </div>

        <div className={styles.faq}>
          <div className={`question ${styles.question}`}>
            <h2 className={`h2 ${styles.h2}`}>Questions les plus <br />fréquentes</h2>
            <p className={`contact ${styles.contact}`}>Envoyez vos questions à <a className={`mail ${styles.mail}`} href="mailto:drill@drill-it.com">drill@drill-it.com</a></p>
           <Link to="/Lessons"> <button className={`btn-cours ${styles.btncours}`}>TROUVEZ VOTRE COURS</button></Link>
          </div>
          <div className={styles.details}>
            <details>
              <summary>Combien ça coûte ?</summary>
              <p className={styles.paragrapheFAQ}>
                Drill-it propose plusieurs abonnements, avec ou sans engagement, à partir de 7,50€/mois.
              </p>
            </details>
            <details>
              <summary>Qu'est-ce qu'on trouve sur Drill-it ?</summary>
              <p className={styles.paragrapheFAQ}>
                Sur Drill-it, vous trouverez des cours en ligne de Jiu-Jitsu brésilien pour tous les niveaux, ainsi
                que des exercices de mobilité et de renforcement de musculaire.
              </p>
            </details>
            <details>
              <summary>Qu'est-ce que le JJB ?</summary>
              <p className={styles.paragrapheFAQ}>
                Le Jiu-Jitsu brésilien (JJB) est un art martial axé sur le combat au sol et les techniques de soumission.
                Développé au Brésil à partir du jiu-jitsu japonais (judo), il permet à une personne plus petite de maîtriser
                un adversaire plus grand en utilisant des étranglements et des clés articulaires. Il est devenu indispensable
                aux pratiquants de MMA.
              </p>
            </details>
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
