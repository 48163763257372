import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./LessonCard.module.scss";

export default function LessonCard({ lesson, setLessonToDisplay }) {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault(); // Empêche la navigation par défaut
    setLessonToDisplay(lesson);
    navigate(`/Lessons/${lesson.id}`);
  };

  return (
    <Link 
      to={`/Lessons/${lesson.id}`} 
      onClick={handleClick} 
      className={styles.lessonCard}
    >
      {/* Div pour l'image */}
      <div className={styles.imageContainer}>
        <img src={lesson.image} alt={lesson.title} className={styles.image} />
      </div>

      {/* Div pour le contenu textuel */}
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{lesson.title}</h3>
        <p className={styles.professor}>par <span>{lesson.professor}</span></p>
        <div className={styles.divTime}>
          <i className={`fa-solid fa-clock ${styles.fa}`}></i>
          <p className={styles.time}>{lesson.duration}</p>
          <p className={styles.level}>{lesson.level}</p>
          <p className={styles.category}>/{lesson.category}</p>
        </div>
      </div>
    </Link>
  );
}
