import styles from './Professesors.module.scss';

export default function ProfessorJJB1() {
  return (
    <div className={`professsorJJB1 ${styles.ProfessorJJB1}`} id='#ProfessorJJB1'>
      <div><h1 className={`h1prof ${styles.h1prof}`}>Thiago Goiabeira</h1></div>
      <div  className={`professsor ${styles.professsor}`}>
        <img className={`Thiago ${styles.Thiago}`} src="Thiago5.jpg" alt="Thiago" />
        <p className={`pJJB ${styles.pJJB}`}>
          Né à Fortaleza, au Brésil, Thiago découvre le Jiu-Jitsu en 2000, dans la célèbre académie Ribeiro, auprès de Xande Ribeiro (hall of famer ADCC). <br /> <br />
          Aujourd'hui ceinture noire 4ème degré, il est le head coach de l'académie Respire Jiu-Jitsu et l'ambassadeur Six Blades en France métropolitaine. <br /><br />
          Compétiteur dévoué, il participe et remporte de grandes compétitions: Champion de France 2019,2020,2021,2022,2023,2024, Champion Europe 2019, Vice-Champion Europe 2022, 3ème AJP 2023. <br /> <br />
          Implanté en Bretagne, il développe le Jiu-Jitsu Brésilien dans la région en proposant une pratique adaptée à chacun,
          simple et efficace. Son expérience l'amène à pratiquer des stages auprès de différents clubs (Melun, Pythagore Bordeaux, CPB Rennes, etc) pour transmettre son savoir-faire.
        </p>
        <div  className={`professsorInfo ${styles.professsorInfo}`}>
          <p className={styles.info1}><i class="fa-solid fa-medal"></i>JJB</p>
          <p className={styles.info}><i class="fa-solid fa-circle-play"></i>131 vidéos</p>
          <p className={styles.info}><i class="fa-solid fa-users-line"></i>Tous niveaux</p>
        </div>
      </div>
    </div>
  );
}
